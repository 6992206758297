<template>
  <div class="container">
    <div class="row gutter">
        <div class="col p-4">
            <div class="c_card_box c_bg_blue_gr1">
                <div class="row row-cols-1 justify-content-center flex-column h-100 px-4 text-center">
                    <div class="col">
                        <div class="row justify-content-center flex-column h-100 px-4 text-center">
                            <h3 class="text-white">Active Doctors</h3>
                            <h4 class="text-white h1"> {{ ehealthData.active_doctors }}</h4>
                        </div>
                    </div>
                   
                    <!-- <div class="col">
                        <div class="row row-cols-lg-6 row-cols-2">
                            <div v-for="item in doctorsStatus" :key="item.id" class="col p-4 align-items-center" :class="item.addColumnClass">
                                <div class="p-4 d-flex justify-content-between rounded-lg align-items-center" :class="item.bgClass">
                                    <div class="icon">
                                        <i v-if="item.iconClass" :class="item.iconClass" style="color: #ffffff;"></i>
                                        <span class="d-block text-light">{{ item.status }}</span>
                                    </div>
                                    <span class="d-block h1 text-white mb-0">
                                       {{ item.total }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                
            </div>
        </div>
        <div class="col p-4">
            <div class="c_card_box c_bg_blue_dark">
                <div class="row row-cols-1 justify-content-center flex-column h-100 px-4 text-center">
                    <div class="col">
                        <div class="row justify-content-center flex-column h-100 px-4">
                            <h3 class="text-white">Active Patient</h3>
                            <h4 class="text-white h1">{{ ehealthData.active_patients }}</h4>
                        </div>
                    </div>
                    <!-- <div class="col">
                        <div class="row row-cols-2">
                            <div v-for="item in PatientStatus" :key="item.id" class="col p-4 align-items-center">
                                <div class="p-4 d-flex justify-content-between rounded-lg align-items-center" :class="item.bgClass">
                                    <div class="icon">
                                        <i v-if="item.iconClass" :class="item.iconClass" style="color: #ffffff;"></i>
                                        <span class="d-block text-light">{{ item.status }}</span>
                                    </div>
                                    <span class="d-block h1 text-white mb-0">
                                    {{ item.total }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                
            </div>
        </div>
        <div class="col p-4">
            <router-link to="/report/doctor-referral/list" target='_blank'>
            <div class="c_card_box c_bg_blue_dark">
                <div class="row row-cols-1 justify-content-center flex-column h-100 px-4 text-center">
                    <div class="col">
                        <div class="row justify-content-center flex-column px-4 h-100">
                            <h3 class="text-white">Center Referral</h3>
                            <h4 class="text-white h1">{{ ehealthData.center_referrals }}</h4>
                        </div>
                    </div>
                    <!-- <div class="col">
                        <div class="row row-cols-2">
                            <div v-for="item in referralStatus" :key="item.id" class="col p-4 align-items-center">
                                <div class="p-4 d-flex justify-content-between rounded-lg align-items-center c_bg_primary">
                                    <div class="icon">
                                        <i v-if="item.iconClass" :class="item.iconClass" style="color: #ffffff;"></i>
                                        <span class="d-block text-light">{{ item.status }}</span>
                                    </div>
                                    <span class="d-block h1 text-white mb-0">
                                    {{ item.total }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                
            </div>
            </router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        ehealthData: {
            type: Object,
            default: {}
        }
    },
    data() {
        return{
            
        }
    }
}
</script>