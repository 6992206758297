<template>
  <div class="c_tab_area position-relative">
    <loader-component v-if="loading"/>
    <div class="c_tab_area_old_dash" v-show="!currentUser.is_top_executive">
      <router-link to="/dashboard/old">Old Dashboard</router-link>
    </div>
    <div class="c_tab_area_filter">
        <b-dropdown id="dropdown-1" :text="periodConverter[filter.period]" class="bg-black">
            <b-dropdown-item :active="filter.period == 'this_month'" @click="setPeriod('this_month')">This Month</b-dropdown-item>
            <b-dropdown-item :active="filter.period == 'this_week'" @click="setPeriod('this_week')">This Week</b-dropdown-item>
            <b-dropdown-item :active="filter.period == 'last_month'" @click="setPeriod('last_month')">Last Month</b-dropdown-item>
            <b-dropdown-item :active="filter.period == 'last_week'" @click="setPeriod('last_week')">Last Week</b-dropdown-item>
        </b-dropdown>
    </div>
    <b-tabs
          active-nav-item-class="c_tab_nav_ative"
          active-tab-class="c_tab_active"
          align="center"
          card

        >
            <b-tab :active="filter.type == 'center'" @click="selectTab('center')">
              <template #title>
                <i class="far fa-hospital"></i> Orko Center
              </template>
              <OrkoCenterCard :dashboardData="dashboardData" :filter="filter" v-if="filter.type == 'center' && dashboardData.ctg"/>
            </b-tab>

            <b-tab :active="filter.type == 'ehealth'" @click="selectTab('ehealth')">
                <template #title>
                  <i class="fas fa-stethoscope"></i> Orko eHealth
                </template>
                <OrkoEhealth :ehealthData="dashboardData" v-if="filter.type == 'ehealth'"/>
            </b-tab>
            <b-tab :active="filter.type == 'app_engagement'" @click="selectTab('app_engagement')">
                <template #title>
                  <i class="fab fa-app-store"></i> App engagement
                </template>
                <OrkoAppActivity :appActivity="dashboardData" :filter="filter" v-if="filter.type == 'app_engagement'"/>
            </b-tab>
            <b-tab :active="filter.type == 'sales'" @click="selectTab('sales')">
                <template #title>
                  <i class="fas fa-money-bill"></i> Sales activity 
                </template>
                <OrkoSalesActivity :dashboardData="dashboardData" v-if="filter.type == 'sales' && dashboardData.ctg" />
            </b-tab>
            <b-tab :active="filter.type == 'crm'" @click="selectTab('crm')">
                <template #title>
                  <i class="fas fa-people-arrows"></i> CRM activity 
                </template>
                <OrkoCrmActivity :crmActivity="dashboardData" v-if="filter.type == 'crm'"  />
            </b-tab>
            <b-tab :active="filter.type == 'others'" @click="selectTab('others')">
                <template #title>
                  <i class="fas fa-tasks"></i> Others 
                </template>
                <OrkoOthers :othersData="dashboardData" v-if="filter.type == 'others'" />
            </b-tab>
        </b-tabs>
    <!-- <div> 
      <div>
        
        <OrkoDashboardWidget/>
      </div>
    </div> -->
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
// import {mapActions} from "vuex";
import OrkoCenterCard from "../../../../components/dashboard/new/orko/OrkoCenterCard.vue";
import OrkoEhealth from "../../../../components/dashboard/new/orko/OrkoEhealth.vue";
import OrkoAppActivity from '../../../../components/dashboard/new/orko/OrkoAppActivity.vue';
import OrkoCrmActivity from "../../../../components/dashboard/new/orko/OrkoCrmActivity.vue";
import OrkoSalesActivity from "../../../../components/dashboard/new/orko/OrkoSalesActivity.vue";
import OrkoOthers from "../../../../components/dashboard/new/orko/OrkoOthers.vue";
// import OrkoDashboardWidget from "../../../../components/dashboard/new/orko/OrkoDashboardWidget";

export default {
  name: "OrkoDashboard",
  components: { OrkoCenterCard, OrkoEhealth, OrkoAppActivity, OrkoSalesActivity, OrkoCrmActivity, OrkoOthers},
  data() {
    return {
      filter: {
        type: 'center',
        period: 'this_month'
      },
      dashboardData: {},
      periodConverter: {
        this_month: 'This Month',
        this_week: 'This Week',
        last_month: 'Last Month',
        last_week: 'Last Week'
      },
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.$store.state.auth.userData;
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);

    if (this.$store.state.auth.isAuthenticated === '') {
      this.$router.push('login');
    }

  },
  created() {
    this.getDashboardCounts();
  },
  methods: {
   
    getDashboardCounts() {
      this.dashboardData = {};
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko-dashboard/counts?api_token=${localStorage.getItem('api_token')}${this.filter.type ? '&type=' + this.filter.type : ''}${this.filter.period ? '&period=' + this.filter.period : ''}`).then(res => {
        this.dashboardData = res.data;
        this.loading = false;
      })
    },
    selectTab(tabname) {
      console.log('Here-------')
      this.filter.type = tabname;
      this.getDashboardCounts();
    },

    setPeriod(period) {
      this.filter.period = period;
      this.getDashboardCounts();
    }

    // showStats() {
    //   this.showChart = true;
    //   let rd = Math.floor(Math.random() * 10 + 1);
    //   this.pieData.datasets[0].data = [];
    //   this.pieData.datasets[0].data = [5, 8, 2];
    // },
  }
};
</script>
<style lang="scss">
.nav {
  .nav-link{
    padding: 0.75rem .6rem !important;
  }
}
.c_tab_area_old_dash{
  z-index: 11;
}
.c_bg{
  &_primary{
    background: #002147;
  }
  &_blue{
    &_dark{
      background: #115caa;
      &_secondary{
        background: #011731;
      }
    }
    &_light{
      background: #1d9bd6;
    }
    &_gr1{
      background: rgb(0,33,71);
      background: linear-gradient(90deg, rgba(0,33,71,1) 0%, rgba(17,92,170,1) 100%);
    }
  }
  &_green{
    &_dark{
      background: #0b6e4f;
    }
  }
  &_orange{
    background: #f26f25;
    &_gr{
      background: rgb(170,123,17);
      background: radial-gradient(circle, rgba(170,123,17,1) 0%, rgba(252,70,107,1) 100%);
    }
  }
  &_red{
    background: #dd0018;
  }
}
.font{
  &_14{
    font-size: 14px !important;
  }
  &_12{
    font-size: 12px !important;
  }
}
.c_tab_area{

  .tabs{
    .card-header{
      background: transparent;
      position: sticky;
      top:0;
      border: none;
      z-index: 10;
      padding: 5px;
    }
  }
  .nav-tabs{
    background: #fff;
    padding: 10px;
    border-radius: 100px;
    display: grid;
    grid-auto-flow: column;
    justify-content: normal !important;
    max-width: 75%;
    margin: 0 auto;
    box-shadow: 0px 1px 4px 2px #94949430;
    position: relative;
    gap: 1px;
    border: none;

    .nav-link{
      border-radius: 100px;
      text-align: center;
      align-items: center;
      justify-content: center;
      color: #022247c2;
      font-weight: 600;
      border: none;

      i{
        color: #022247c2 !important;
        margin-right: 5px;
      }

      &:hover{
        background: transparent;
        color: #022247;
        i{
          color: #022247 !important;
        }
      }
      &.active{
        background: #eff2f5;
        color: #022247;
        border: none;
        box-shadow: 0px 0px 3px #00000040;
        font-weight: 900;
        i{
          color: #022247 !important;
        }
      }
      &:focus{
        outline: none;
      }

    }

    
  }
  .tab-content{
    background: #fff;
    border-radius: 20px;
    margin-top: -30px;
    padding-top: 30px;
    padding-bottom: 12px;
  }

  &_filter{
    position: absolute;
    right: 0;
    top: -2px;
    z-index: 11;
    .btn.dropdown-toggle.btn-secondary{
      border-radius: 100px;
      font-weight: 900;
      background: #fff;
      font-size: 12px;
    }
  }
  &_old_dash {
    position: absolute;
    left: 0;
    top: 8px;
    a {
      border-radius: 100px;
      font-weight: 900;
      background: #fff;
      font-size: 12px;
      color: #f26f25;
      padding: 8px 8px;
    }
  }
}
</style>