<template>
  <div class="container">
    <!-- Start CTG Center -->
    <div class="row gutter">
      <div class="col-sm-12 total_card p-4">
        <div class="c_card_box total_card_box">
          <div
            class="d-flex justify-content-between flex-column c_card_box_inner"
          >
            <div class="row">
               <!-- Card Left Column -->
              <div
                class="col-sm-3 d-flex justify-content-center align-items-center"
              >
                <div class="c_card_box_content">
                  <h4 class="center-title">CTG</h4>

                  <span class="d-block text-white">Total Target</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{  dashboardData.ctg.session_target }}</span
                  >
                  <span class="d-block text-white">Total Collection</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{ dashboardData.ctg.session_income }}</span>

                  <span class="d-block text-white">Session Booked</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >{{ dashboardData.ctg.total_session_booked }}</span>

                  <span class="d-block text-white">Session Yet to Book</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >{{ dashboardData.ctg.session_count_due }}</span>
                  <span class="d-block text-white">Payment Yet to Receive</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{ dashboardData.ctg.session_due }}</span>
                    
                </div>
              </div>
               <!-- Card Right Column -->
              <div class="col-sm-9 overflow-hidden">
                <div
                  class="row justify-content-between flex-nowrap overflow-auto"
                >
                <!-- BD Team Report -->
                  <div class="col-6 mb-4">
                    <router-link to="/occ/report/center" target="_blank">
                      <div class="icon_box">
                        <div class="d-flex justify-content-between pb-1 mb-3 border-bottom border-light">
                          <span class="icon_box_caption c_title" title="Business Development">BD</span>
                          <span class="c_card_box_content_price text-white" :class="achievementClass(calculateProgress(dashboardData.ctg.session_income_bd, calculateTarget('bd', dashboardData.ctg.session_target)))"
                                >{{  calculateProgress(dashboardData.ctg.session_income_bd, calculateTarget('bd', dashboardData.ctg.session_target)) }}%</span
                              >
                        </div>
                        <div class="row">
                          <div class="col-6 text-left">
                            <span class="text-white">Total Target</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{  calculateTarget('bd', dashboardData.ctg.session_target) }}</span
                              > 
                            <span class="text-white">Total Collection</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{ dashboardData.ctg.session_income_bd }}</span
                              >
                            <span class="text-white">Payment Yet to Receive</span>
                            <span class="c_card_box_content_price text-white">
                              &#2547; {{ dashboardData.ctg.session_due_bd }}
                            </span>
                          </div>
                          <div class="col-6 text-right">
                            <span class="text-white">Sessions Booked</span>
                            <span class="c_card_box_content_price text-white">
                              {{ dashboardData.ctg.session_count_bd }}
                            </span> 
                            <span class="text-white">Sessions Taken</span>
                            <span class="c_card_box_content_price text-white">
                              {{ dashboardData.ctg.session_served_bd }}
                            </span> 
                            <span class="d-block text-white">Session Yet to Book</span>
                            <span class="d-block c_card_box_content_price text-white"
                              >{{ dashboardData.ctg.session_count_due_bd }}</span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- CRM Team Report -->
                  <div class="col-6 mb-4">
                    <router-link to="/occ/report/center" target="_blank">
                      <div class="icon_box">
                        <div class="d-flex justify-content-between pb-1 mb-3 border-bottom border-light">
                          <span class="icon_box_caption c_title" title="Business Development">CRM</span>
                          <span class="c_card_box_content_price text-white" :class="achievementClass(calculateProgress(dashboardData.ctg.session_income_crm, calculateTarget('crm', dashboardData.ctg.session_target)))"
                                >{{  calculateProgress(dashboardData.ctg.session_income_crm, calculateTarget('crm', dashboardData.ctg.session_target)) }}%</span
                              > 
                        </div>
                        <div class="row">
                          <div class="col-6 text-left">
                            <span class="text-white">Total Target</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{  calculateTarget('crm', dashboardData.ctg.session_target) }}</span
                              > 
                            <span class="text-white">Total Collection</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{ dashboardData.ctg.session_income_crm }}</span
                              >
                            <span class="text-white">Payment Yet to Receive</span>
                            <span class="c_card_box_content_price text-white">
                              &#2547; {{ dashboardData.ctg.session_due_crm }}
                            </span>
                          </div>
                          <div class="col-6 text-right">
                            <span class="text-white">Sessions Booked</span>
                            <span class="c_card_box_content_price text-white"
                                >{{ dashboardData.ctg.session_count_crm }}</span
                              > 
                            <span class="text-white">Sessions Taken</span>
                            <span class="c_card_box_content_price text-white">
                              {{ dashboardData.ctg.session_served_crm }}
                            </span> 
                            <span class="d-block text-white">Session Yet to Book</span>
                            <span class="d-block c_card_box_content_price text-white"
                              >{{ dashboardData.ctg.session_count_due_crm }}</span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- Start CM Center -->
    <div class="row gutter">
      <div class="col-sm-12 total_card p-4">
        <div class="c_card_box total_card_box">
          <div
            class="d-flex justify-content-between flex-column c_card_box_inner"
          >
            <div class="row">
              <!-- Card Left Column  -->
              <div
                class="col-sm-3 d-flex justify-content-center align-items-center"
              >
                <div class="c_card_box_content">
                  <h4 class="center-title">CUMILLA</h4>

                  <span class="d-block text-white">Total Target</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{  dashboardData.cm.session_target }}</span
                  >

                  <span class="d-block text-white">Total Collection</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{ dashboardData.cm.session_income }}</span
                  >
                  <span class="d-block text-white">Session Booked</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >{{ dashboardData.cm.total_session_booked }}</span>
                  <span class="d-block text-white">Session Yet to Book</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >{{ dashboardData.cm.session_count_due }}</span>
                  <span class="d-block text-white">Payment Yet to Receive</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{ dashboardData.cm.session_due }}</span>
                </div>
              </div>
              <!-- Card Right Column -->
              <div class="col-sm-9 overflow-hidden">
                <div
                  class="row justify-content-between flex-nowrap overflow-auto"
                >
                <!-- BD Team Report -->
                  <div class="col-6 mb-4">
                    <router-link to="/occ/report/center" target="_blank">
                      <div class="icon_box">
                        <div class="d-flex justify-content-between pb-1 mb-3 border-bottom border-light">
                          <span class="icon_box_caption c_title">BD</span>
                          <span class="c_card_box_content_price text-white" :class="achievementClass(calculateProgress(dashboardData.cm.session_income_bd, calculateTarget('bd', dashboardData.cm.session_target)))"
                                >{{  calculateProgress(dashboardData.cm.session_income_bd, calculateTarget('bd', dashboardData.cm.session_target)) }}%</span
                              > 
                        </div>
                        <div class="row">
                          <div class="col-6 text-left">
                            <span class="text-white">Total Target</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{  calculateTarget('bd', dashboardData.cm.session_target) }}</span
                              > 
                            <span class="text-white">Total Collection</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{ dashboardData.cm.session_income_bd }}</span
                              >
                            <span class="text-white">Payment Yet to Receive</span>
                            <span class="c_card_box_content_price text-white">
                              &#2547; {{ dashboardData.cm.session_due_bd }}
                            </span>
                          </div>
                          <div class="col-6 text-right">
                            <span class="text-white">Sessions Booked</span>
                            <span class="c_card_box_content_price text-white"
                                >{{ dashboardData.cm.session_count_bd }}</span
                              > 
                            <span class="text-white">Sessions Taken</span>
                            <span class="c_card_box_content_price text-white">
                              {{ dashboardData.cm.session_served_bd }}
                            </span>
                            <span class="d-block text-white">Session Yet to Book</span>
                            <span class="d-block c_card_box_content_price text-white"
                              >{{ dashboardData.cm.session_count_due_bd }}</span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <!-- CRM Team Report -->
                  <div class="col-6 mb-4">
                    <router-link to="/occ/report/center" target="_blank">
                      <div class="icon_box">
                        <div class="d-flex justify-content-between pb-1 mb-3 border-bottom border-light">
                          <span class="icon_box_caption c_title">CRM</span>
                          <span class="c_card_box_content_price text-white" :class="achievementClass(calculateProgress(dashboardData.cm.session_income_crm, calculateTarget('crm', dashboardData.cm.session_target)))"
                                >{{  calculateProgress(dashboardData.cm.session_income_crm, calculateTarget('crm', dashboardData.cm.session_target)) }}%</span
                              > 
                        </div>
                        <div class="row">
                          <div class="col-6 text-left">
                            <span class="text-white">Total Target</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{  calculateTarget('crm', dashboardData.cm.session_target) }}</span
                              > 
                            <span class="text-white">Total Collection</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{ dashboardData.cm.session_income_crm }}</span
                              >
                            <span class="text-white">Payment Yet to Receive</span>
                            <span class="c_card_box_content_price text-white">
                              &#2547; {{ dashboardData.cm.session_due_crm }}
                            </span>
                          </div>
                          <div class="col-6 text-right">
                            <span class="text-white">Sessions Booked</span>
                            <span class="c_card_box_content_price text-white"
                                >{{ dashboardData.cm.session_count_crm }}</span
                              > 
                            <span class="text-white">Sessions Taken</span>
                            <span class="c_card_box_content_price text-white">
                              {{ dashboardData.cm.session_served_crm }}
                            </span>
                            <span class="d-block text-white">Session Yet to Book</span>
                            <span class="d-block c_card_box_content_price text-white"
                              >{{ dashboardData.cm.session_count_due_crm }}</span>
                          </div>
                        </div>

                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <!-- Start B.Baria Center -->
    <div class="row gutter">
      <div class="col-sm-12 total_card p-4">
        <div class="c_card_box total_card_box">
          <div
            class="d-flex justify-content-between flex-column c_card_box_inner"
          >
            <div class="row">
              <!-- Start Left Column  -->
              <div
                class="col-sm-3 d-flex justify-content-center align-items-center"
              >
                <div class="c_card_box_content">
                  <h4 class="center-title">B.BARIA</h4>

                  <span class="d-block text-white">Total Target</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{  dashboardData.bbaria.session_target }}</span
                  >

                  <span class="d-block text-white">Total Collection</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{ dashboardData.bbaria.session_income }}</span
                  >
                  <span class="d-block text-white">Session Booked</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >{{ dashboardData.bbaria.total_session_booked }}</span>
                  <span class="d-block text-white">Session Yet to Book</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >{{ dashboardData.bbaria.session_count_due }}</span>
                  <span class="d-block text-white">Payment Yet to Receive</span>
                  <span class="d-block c_card_box_content_price text-white"
                    >&#2547; {{ dashboardData.bbaria.session_due }}</span>
                </div>
              </div>

              <!-- Start Right Column  -->
              <div class="col-sm-9 overflow-hidden">
                <div
                  class="row justify-content-between flex-nowrap overflow-auto"
                >
                <!-- Start BD team Report-->
                  <div class="col-6 mb-4">
                    <router-link to="/occ/report/center" target="_blank">
                      <div class="icon_box">
                        <div class="d-flex justify-content-between pb-1 mb-3 border-bottom border-light">
                          <span class="icon_box_caption c_title">BD</span>
                          <span class="c_card_box_content_price text-white" :class="achievementClass(calculateProgress(dashboardData.bbaria.session_income_bd, calculateTarget('bd', dashboardData.bbaria.session_target)))"
                                >{{  calculateProgress(dashboardData.bbaria.session_income_bd, calculateTarget('bd', dashboardData.bbaria.session_target)) }}%</span
                              > 
                        </div>
                        <div class="row">
                          <div class="col-6 text-left">
                            <span class="text-white">Total Target</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{  calculateTarget('bd', dashboardData.bbaria.session_target) }}</span
                              > 
                            <span class="text-white">Total Collection</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{ dashboardData.bbaria.session_income_bd }}</span
                              >
                            <span class="text-white">Payment Yet to Receive</span>
                            <span class="c_card_box_content_price text-white">
                              &#2547; {{ dashboardData.bbaria.session_due_bd }}
                            </span>
                          </div>
                          <div class="col-6 text-right">
                            <span class="text-white">Sessions Booked</span>
                            <span class="c_card_box_content_price text-white"
                                >{{ dashboardData.bbaria.session_count_bd }}</span
                              > 
                            <span class="text-white">Sessions Taken</span>
                            <span class="c_card_box_content_price text-white">
                              {{ dashboardData.bbaria.session_served_bd }}
                            </span>
                            <span class="d-block text-white">Session Yet to Book</span>
                            <span class="d-block c_card_box_content_price text-white"
                              >{{ dashboardData.bbaria.session_count_due_bd }}</span>
                          </div>
                        </div>

                      </div>
                    </router-link>
                  </div>
                  <!-- Start CRM Team Report -->
                  <div class="col-6 mb-4">
                    <router-link to="/occ/report/center" target="_blank">
                      <div class="icon_box">
                        <div class="d-flex justify-content-between pb-1 mb-3 border-bottom border-light">
                          <span class="icon_box_caption c_title">CRM</span>
                          <span class="c_card_box_content_price text-white" :class="achievementClass(calculateProgress(dashboardData.bbaria.session_income_crm, calculateTarget('crm', dashboardData.bbaria.session_target)))"
                                >{{  calculateProgress(dashboardData.bbaria.session_income_crm, calculateTarget('crm', dashboardData.bbaria.session_target)) }}%</span
                              > 
                        </div>
                        <div class="row">
                          <div class="col-6 text-left">
                            <span class="text-white">Total Target</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{  calculateTarget('crm', dashboardData.bbaria.session_target) }}</span
                              > 
                            <span class="text-white">Total Collection</span>
                            <span class="c_card_box_content_price text-white"
                                >&#2547; {{ dashboardData.bbaria.session_income_crm }}</span
                              >
                            <span class="text-white">Payment Yet to Receive</span>
                            <span class="c_card_box_content_price text-white">
                              &#2547; {{ dashboardData.bbaria.session_due_crm }}
                            </span>
                          </div>
                          <div class="col-6 text-right">
                            <span class="text-white">Sessions Booked</span>
                            <span class="c_card_box_content_price text-white"
                                >{{ dashboardData.bbaria.session_count_crm }}</span
                              > 
                            <span class="text-white">Sessions Taken</span>
                            <span class="c_card_box_content_price text-white">
                              {{ dashboardData.bbaria.session_served_crm }}
                            </span>
                            <span class="d-block text-white">Session Yet to Book</span>
                            <span class="d-block c_card_box_content_price text-white"
                              >{{ dashboardData.bbaria.session_count_due_crm }}</span>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="custom_tab_table">
      <b-card no-body>
        <b-tabs pills card vertical>
          <b-tab title="BD" active>
            <b-card-text>
              <b-table :items="dashboardData.teams.bd_users" :fields="fields" tbody-tr-class="text-center" thead-tr-class="text-center" sticky-header>
                <template #cell(target_income)="row">
                  {{ getTeamTarget(row.item.id) }}
                </template>
                <template #cell(total_income)="row">
                  <strong :class="achievementClass(calculateProgress(row.item.total_income, getTeamTarget(row.item.id)))">
                    {{ row.item.total_income }}
                  </strong>
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
          <b-tab title="CRM">
            <b-card-text>
              <b-table :items="dashboardData.teams.crm_users" :fields="fields" tbody-tr-class="text-center" thead-tr-class="text-center" sticky-header>
                <template #cell(target_income)="row">
                    {{ getTeamTarget(row.item.id) }}
                </template>
                <template #cell(total_income)="row">
                  <strong :class="achievementClass(calculateProgress(row.item.total_income, getTeamTarget(row.item.id)))">
                    {{ row.item.total_income }}
                  </strong>
                </template>
              </b-table>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
      fields: [
        {key:"fullname", label: "Name"},
        {key:"target_income", label: "Target Income"},
        {key:"total_service", label: "Sessions Booked"},
        {key:"total_income", label: "Total Income"}
      ]

    };
  },
  props: {
    dashboardData: {
      type: Object,
      default: {},
    }
  },
  methods: {
    serviceWiseTotal(service) {
      return this.dashboardData.ctg
        ? parseFloat(
            this.dashboardData.ctg[service] +
              this.dashboardData.cm[service] +
              this.dashboardData.bbaria[service]
          )
        : 0;
    },
    calculateTarget(team, value) {
      if(team == 'crm') {
        return value?Math.round(value*0.35):0; // 35%
      } else {
        return value?Math.round(value*0.65):0; // 65%
      }
    },
    calculateProgress(achieved, target) {
      if(target > 0) {
        // return Math.round((achieved*100)/target, 2);
        return Math.round(((achieved*100)/target + Number.EPSILON) * 100) / 100
      }
      return 0;
    },
    achievementClass(achieved) {
      if(achieved >= 100) {
        return 'text-success';
      } else if(achieved >= 80) {
        return 'text-primary';
      } else if(achieved >= 60) {
        return 'text-warning';
      } else {
        return 'text-danger'
      }
    },
    getTeamTarget(userId) {
      let user = this.dashboardData.team_targets.find(item => item.user_id == userId);  
      if(user) return user.target_income;
      else return 0
    }
   
  },
  mounted() {
   //
  },
};
</script>

<style lang="scss">
.c_card_box {
  min-height: 125px;
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  background-clip: padding-box;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  &.total_card_box {
    background-color: #002147;

    .c_card_box_title {
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
    }

    .icon_box {
      background: #011731;
      padding: 20px;
      border-radius: 20px;
      text-align: center;
      color: #fff;
      &_img {
        width: 40px;
        margin: 0 auto;
        img {
          max-width: 100%;
        }
      }
    }
  }
  &_inner {
    // min-height: 150px;
    height: 100%;
  }

  &_title {
    font-size: 22px;
    font-weight: 600;
  }
  &_content {
    &_price {
      font-size: 24px;
      font-weight: 600;
      display: block;
    }
  }
}

.c_title {
        display: block;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }

.icon {
  text-align: center;
  span {
    font-size: 8px;
    font-weight: 600;
  }
}

.center-title {
  color: #f26f25;
  font-size: 22px;
  font-weight: 600;
  margin-left: -30px;
  margin-bottom: 10px;
}

.show_more_button {
  border: 1px solid #002147;
  border-radius: 100px;
  text-align: center;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 600;
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: center;
}

.c_tab_area {
  .custom_tab_table{
    .card{
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    }
    .tabs{
      .card-header{
        z-index: 1;
      }
    }
    .tab-content{
      margin-top: 0;
      padding: 0;
      border-left: 1px solid #ebedf3;
      border-radius: 0;
      height: 400px;
      overflow-y: auto;
    }
    .nav{
      &.nav-pills{
        padding: 20px;
        z-index: 1;
        li{
          min-width: 100px;
          a{
            display: block;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            &.active{
              background-color: #012047;
              &:hover{
                color: #fff;
              }
            }
            &:hover{
              color: #012047;
            }
          }
        }
      }
    }
  }
}

</style>
