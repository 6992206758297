<template>
    <div class="container">
        <div class="row gutter">
            <!-- Start Title -->
            <!-- <div class="col-sm-12 total_card p-4 mt-4">
                <h1 class="text-center font-weight-bolder text-capitalize">
                    Others
                </h1>
            </div> -->

            <!--Start Corporate Patients -->
            <div class="col-md-6 p-4" v-if="othersData.corporate_patients">
                <div class="c_card_box c_bg_primary">
                    <div class="row py-4">
                        <div class="col-md-7">
                            <div class="row justify-content-between flex-column h-100 px-4">
                                <h3 class="text-white">Corporate Patients</h3>
                                <h4 class="text-white h1">
                                    <span class="text-muted d-block h6">Total Registered</span>
                                    {{ othersData.corporate_patients }}
                                </h4>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <!--Start SERVICE PROVIDER'S COUNTER -->
            <div class="col-md-6 p-4" v-if="othersData.service_provider">
                <div class="c_card_box c_bg_blue_dark">
                <div class="row row-cols-1 justify-content-between">
                    <div class="col">
                    <div class="row justify-content-between flex-column h-100 px-4">
                        <h3 class="text-white">SERVICE COUNTER</h3>
                        <h4 class="text-white h1">
                        {{
                            othersData.service_provider.physio +
                            othersData.service_provider.physio_assistant
                        }}
                        </h4>
                    </div>
                    </div>
                    <div class="col">
                    <div class="row">
                        <div class="col p-4 align-items-center">
                        <div
                            class="bg-success p-4 d-flex justify-content-between rounded-lg align-items-center"
                        >
                            <div class="icon">
                            <i class="fas fa-user-circle" style="color: #ffffff;"></i>
                            <span class="d-block text-light">CONSULTATION</span>
                            </div>
                            <span class="d-block h1 text-white mb-0">
                            {{ othersData.service_provider.physio }}
                            </span>
                        </div>
                        </div>
                        <div class="col p-4">
                        <div
                            class="c_bg_orange p-4 d-flex justify-content-between rounded-lg align-items-center"
                        >
                            <div class="icon">
                            <i class="fas fa-users" style="color: #ffffff;"></i>
                            <span class="d-block text-light">SESSION</span>
                            </div>
                            <span class="d-block h1 text-white mb-0">
                            {{ othersData.service_provider.physio_assistant }}
                            </span>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
             <!--Start WELFARE FUND APPLICATION -->
            <div class="col-md-12 p-4" v-if="othersData.welfare">
                <div class="c_card_box c_bg_primary">
                <router-link to="/welfare-application" target="_blank">
                    <div class="row">
                    <div class="col-md-4">
                        <div class="row justify-content-between flex-column h-100 px-4">
                        <h3 class="text-white">WELFARE FUND APPLICATION</h3>
                        <h4 class="text-white h1">
                            {{ othersData.welfare.total_application }}
                        </h4>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                        <div class="col p-4 align-items-center">
                            <div
                            class="bg-success p-4 d-flex justify-content-between rounded-lg align-items-center"
                            >
                            <div class="icon">
                                <i
                                class="far fa-thumbs-up fa-lg h4 mb-0"
                                style="color: #ffffff;"
                                ></i>
                                <span class="d-block text-light">APPROVED</span>
                            </div>
                            <span class="d-block h1 text-white mb-0">
                                {{ othersData.welfare.total_approved }}
                            </span>
                            </div>
                        </div>
                        <div class="col p-4">
                            <div
                            class="c_bg_orange p-4 d-flex justify-content-between rounded-lg align-items-center"
                            >
                            <div class="icon">
                                <i
                                class="fas fa-exclamation-circle"
                                style="color: #ffffff;"
                                ></i>
                                <span class="d-block text-light">PENDING</span>
                            </div>
                            <span class="d-block h1 text-white mb-0">
                                {{ othersData.welfare.total_pending }}
                            </span>
                            </div>
                        </div>
                        <div class="col p-4">
                            <div
                            class="c_bg_red p-4 d-flex justify-content-between rounded-lg align-items-center"
                            >
                            <div class="icon">
                                <i
                                class="far fa-times-circle"
                                style="color: #ffffff;"
                                ></i>
                                <span class="d-block text-light">REJECTED</span>
                            </div>
                            <span class="d-block h1 text-white mb-0">
                                {{ othersData.welfare.total_rejected }}
                            </span>
                            </div>
                        </div>
                        <div class="col-12 p-4">
                            <div
                            class="c_bg_blue_dark p-4 d-flex justify-content-between rounded-lg align-items-center"
                            >
                            <div class="icon">
                                <i class="fas fa-user-tag" style="color: #ffffff;"></i>
                                <span class="d-block text-light">TOTAL DISCOUNT</span>
                            </div>
                            <span class="d-block h1 text-white mb-0">
                                {{ othersData.welfare.discount_amount }}
                            </span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "OrkoOthers",
    components: {},
    props: {
        othersData: {
            type: Object,
            default: {}
        },
    },
    data(){
        return{
        }
    }
}
</script>