<template>
    <div class="container">
        <div class="row gutter">
            <!-- Start Title -->
            <!-- <div class="col-sm-12 total_card p-4 mt-4">
                <h1 class="text-center font-weight-bolder text-capitalize">
                    Orko App engagement 
                </h1>
            </div> -->

             <!--Start Engagement -->
             <div class="col-md-12 p-4" v-if="appActivity.engagement">
                <router-link to="/app/activity/reports" target='_blank'>
                    <div class="c_card_box c_bg_primary">
                        <div class="row py-4">
                            <div class="col-md-4">
                                <div class="row justify-content-between flex-column h-100 px-4">
                                    <h3 class="text-white">Engagement</h3>
                                    <h4 class="text-white h1">
                                        <span class="text-muted d-block h6">Total Users</span>
                                        {{ appActivity.engagement.total }}
                                    </h4>
                                </div>
                            </div>
                            <div class="col-md-8 border-left">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="text-white text-right">Apps Engagement</h3>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-user-md" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Pro App</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ appActivity.engagement.pro_app }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-user-injured" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">All App</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ appActivity.engagement.all_app }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </router-link>
            </div>

            <!--Start Notification -->
            <div class="col-md-6 p-4" v-if="appActivity.notification">
                <router-link to="/custom-notification-list" target='_blank'>
                    <div class="c_card_box c_bg_primary">
                        <div class="row py-4">
                            <div class="col-md-4">
                                <div class="row justify-content-between flex-column h-100 px-4">
                                    <h3 class="text-white">Notification</h3>
                                    <h4 class="text-white h1">
                                        <span class="text-muted d-block h6">Total Sent</span>
                                        {{ appActivity.notification.total }}
                                    </h4>
                                </div>
                            </div>
                            <div class="col-md-8 border-left">
                                <div class="row">
                                    <div class="col-12">
                                        <h3 class="text-white text-right"> &nbsp; </h3>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-user-md" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Doctors</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ appActivity.notification.doctor }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-user-injured" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Patients</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ appActivity.notification.patient }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </router-link>
            </div>
            <!--Start Articles -->
            <div class="col-md-4 p-4" v-if="appActivity.blog">
                <router-link to="/blogs/posts" target='_blank'>
                    <div class="c_card_box c_bg_blue_dark">
                        <div class="row row-cols-1 justify-content-between">
                            <div class="col-12">
                                <div class="row justify-content-between px-4">
                                    <h3 class="text-white">Total Article</h3>
                                    <h4 class="text-white h1">{{ appActivity.blog.total }}</h4>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col p-4 align-items-center">
                                        <div class="bg-success p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-check-circle" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Published</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ appActivity.blog.published }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4">
                                        <div class="c_bg_orange p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="far fa-clock" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Pending</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ appActivity.blog.pending }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </router-link>
            </div>
            <!--Start Active User -->
            <div class="col-md-2 p-4" v-if="appActivity.feedback">
                <router-link to="/service/feedbacks" target='_blank'>
                <div class="c_card_box c_bg_blue_dark">
                    <div class="row row-cols-1 justify-content-center align-items-center">
                        <div class="col">
                            <div class="row justify-content-center flex-column h-100 px-4">
                                <h3 class="text-white">Service Feedbacks</h3>
                                <h4 class="text-white h1">{{ appActivity.feedback.total }}</h4>
                            </div>
                        </div>
                    </div>
                   
                </div>
                </router-link>
            </div>

            <div class="col-md-12 p-4" v-if="showAppBarChart">
                <div class="c_card_box">
                    <h3>App Engagement</h3>
                    <div class="row">
                        <div class="col-md-12">
                        <apexchart
                            class="card-rounded-bottom"
                            :options="appChartOptions"
                            :series="appSeries"
                            type="bar"
                            height="400"
                        ></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    props: {
        appActivity: {
            type: Object,
            default: {}
        },
        filter: {
            type: Object,
            default: {},
        },
    },
    data(){
        return{

            appChartOptions: {
                colors : ['#002147', '#f26f25'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    labels: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    }
                    },
                    decimalsInFloat: 0,
                },
                dataLabels: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    },
                },
            },
            appSeries: [
                {
                    name: "Pro App",
                    data: []
                },
                {
                    name: "All App",
                    data: []
                },
            ],
            
            showAppBarChart: false,
        };
    },
    methods: {
        appWiseData() {
            this.showAppBarChart = false;
            axios
                .get(
                `${
                    process.env.VUE_APP_API_URL
                }/api/v2/orko-dashboard/date-wise-engagements?api_token=${localStorage.getItem(
                    "api_token"
                )}${this.filter.type ? "&type=" + this.filter.type : ""}${
                    this.filter.period ? "&period=" + this.filter.period : ""
                }`
                )
                .then((res) => {
                    let appData = res.data.data.data;
                    this.appSeries[0].data = appData.map((item) =>
                        parseInt(item.all_app)
                    );
                    this.appSeries[1].data = appData.map((item) =>
                        parseInt(item.pro_app)
                    );
                    this.appChartOptions.xaxis.categories = appData.map(
                        (item) => item.date
                    );
                    setTimeout(() => {
                        this.showAppBarChart = true;
                    }, 250);
                });
            },
        },
    watch: {
        filter: {
            handler() {
                this.appWiseData();
            },
            deep: true,
            immediate: false,
        },
    },
    mounted() {
        this.appWiseData();
    },
}
</script>