<template>
  <div class="container">
    <div class="row gutter">
      <!-- Start Total income -->
      <div class="col-sm-12 total_card p-4">
        <div class="c_card_box total_card_box">
          <div
            class="d-flex justify-content-between flex-column c_card_box_inner"
          >
            <!-- <h6 class="c_card_box_title">
                           Orko Center
                        </h6> -->
            <div class="row">
              <div
                class="col-sm-3 d-flex justify-content-center align-items-end"
              >
                <div class="c_card_box_content">
                  <span class="d-block text-white">Total Income</span>
                  <span class="d-block c_card_box_content_price text-white">
                    &#2547; {{Math.round(this.dashboardData.total_income)}}
                  </span>
                  <span class="d-block text-white">Total Target</span>
                  <span class="d-block c_card_box_content_price text-white">
                    &#2547; {{ totalTarget() }}
                  </span>
                  
                </div>
              </div>
              <div class="col-sm-9 overflow-hidden">
                <div
                  class="row justify-content-between flex-nowrap overflow-auto"
                >
                  <div class="col-4">
                    <router-link to="/occ/report/center" target="_blank" class="h-100">
                      <div class="icon_box h-100">
                        <div class="icon_box_img">
                          <img src="../../../../assets/img/people.svg" />
                        </div>
                        <span class="icon_box_caption">PHYSIOTHERAPY</span>
                        <span
                          class="d-block c_card_box_content_price icon_box_price"
                          >&#2547; {{ serviceWiseTotal("pht_income") }}</span
                        >
                        <span class="d-block">
                          Target: &#2547; {{Math.round(this.dashboardData.targets.pht_target)}}
                        </span>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-4">
                    <router-link to="/occ/report/center" target="_blank" class="h-100">
                      <div class="icon_box h-100">
                        <div class="icon_box_img">
                          <img src="../../../../assets/img/footprints.svg" />
                        </div>
                        <span class="icon_box_caption">ZERO CLUBFOOT</span>
                        <span
                          class="d-block c_card_box_content_price icon_box_price"
                          >&#2547; {{ serviceWiseTotal("zcf_income") }}</span
                        >
                        <span class="d-block">
                          Target: &#2547; {{Math.round(this.dashboardData.targets.zcf_target)}}
                        </span>
                        
                      </div>
                    </router-link>
                  </div>
                  <div class="col-4">
                    <router-link to="/occ/report/center" target="_blank" class="h-100">
                      <div class="icon_box h-100">
                        <div class="icon_box_img">
                          <img src="../../../../assets/img/tooth.svg" />
                        </div>
                        <span class="icon_box_caption">DENTAL</span>
                        <span
                          class="d-block c_card_box_content_price icon_box_price"
                          >&#2547; {{ serviceWiseTotal("dental_income") }}</span
                        >
                        <span class="d-block">
                          Target: &#2547; {{Math.round(this.dashboardData.targets.dental_target)}}
                        </span>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-4">
                    <router-link to="/occ/report/center" target="_blank" class="h-100">
                      <div class="icon_box h-100">
                        <div class="icon_box_img">
                          <img src="../../../../assets/img/broken-leg.png" />
                        </div>
                        <span class="icon_box_caption">PLASTER MATERIAL</span>
                        <span
                          class="d-block c_card_box_content_price icon_box_price"
                          >&#2547; {{ serviceWiseTotal("plaster_material_income") }}</span
                        >
                        <span class="d-block">
                          Target: &#2547; 0
                        </span>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-4">
                    <router-link to="/occ/report/center" target="_blank" class="h-100">
                      <div class="icon_box h-100">
                        <div class="icon_box_img">
                          <img src="../../../../assets/img/home-service.svg" />
                        </div>
                        <span class="icon_box_caption">Home Service</span>
                        <span
                          class="d-block c_card_box_content_price icon_box_price"
                          >&#2547; {{ serviceWiseTotal("home_service") }}</span
                        >
                      </div>
                    </router-link>
                  </div>
                  <div class="col-4">
                    <router-link to="/occ/report/center" target="_blank" class="h-100">
                      <div class="icon_box h-100">
                        <div class="icon_box_img">
                          <img src="../../../../assets/img/iconsulation.svg" />
                        </div>
                        <span class="icon_box_caption">S.CONSULTATION</span>
                        <span
                          class="d-block c_card_box_content_price icon_box_price"
                          >&#2547;
                          {{ serviceWiseTotal("in_house_doc_consult") }}</span
                        >
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Start Center Card -->
      <div v-if="centerWiseData" class="col-12 p-4">
        <div class="row gutter justify-content-center">
          <div v-for="item in centerWiseData" :key="item.id" class="col-md-4">
            <CenterCard
              :centerName="item.centerName"
              :centerData="dashboardData[item.propertyName]"
              :seeMore="toggleItem"
            />
          </div>
        </div>
        <button
          @click="toggleItem = !toggleItem"
          class="text-center mt-4 show_more_button mx-auto"
        >
          <template v-if="!toggleItem">
            See More
            <!-- <i class="fas fa-plus" style="color: #ffffff;"></i> -->
          </template>
          <template v-else-if="toggleItem">
            Show less
            <!-- <i class="fas fa-minus" style="color: #ffffff;"></i> -->
          </template>
        </button>
      </div>
      <!--Start Registration Sources -->
      <div class="col-md-12 p-4">
        <div class="c_card_box c_bg_primary" v-if="dashboardData.source_wise_reg">
          <!-- <router-link to="/welfare-application" target="_blank"> -->
            <div class="row">
              <div class="col-md-3">
                <div class="row justify-content-between flex-column h-100 px-4">
                  <div>
                    <h3 class="text-white">Registration Sources</h3>
                    <h4 class="text-white h1">
                      {{ regCount }}
                    </h4>
                  </div>
                  <div>
                    <h3 class="text-white">Source Wise Service Count</h3>
                    <h4 class="text-white h1">
                      {{ sourceWiseService() }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="row">
                    <div class="col-md-4 p-4 align-items-center">
                        <div class="c_bg_orange p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                                <i class="fas fa-vr-cardboard" style="color: #ffffff;"></i>
                                <span class="d-block text-light">Sticker Source</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                               {{ getRegCountBySource('Poster') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.poster }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 p-4 align-items-center">
                        <div class="bg-success p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                                <i class="fas fa-share-alt" style="color: #ffffff;"></i>
                                <span class="d-block text-light">Social Source</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                              {{ getRegCountBySource('Social') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.social }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 p-4 align-items-center">
                        <div class="c_bg_blue_light p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                                <i class="fas fa-user-md" style="color: #ffffff;"></i>
                                <span class="d-block text-light">Doctor Refer</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                              {{ getRegCountBySource('Doctor Referral') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.doctor_referral }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 p-4 align-items-center">
                        <div class="c_bg_blue_dark p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                                <i class="fas fa-hospital-user" style="color: #ffffff;"></i>
                                <span class="d-block text-light">Patient Refer</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                              {{ getRegCountBySource('Patient Referral') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.patient_referral }}
                            </span>
                        </div>
                    </div>
                   
                    <div class="col-md-6 p-4 align-items-center">
                        <div class="c_bg_orange p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                              <i class="fas fa-portrait" style="color: #ffffff;"></i>
                                <span class="d-block text-light">App source</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                              {{ getRegCountBySource('register') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.register }}
                            </span>
                        </div>
                    </div>

                    <div class="col-md-4 p-4 align-items-center">
                        <div class="bg-success p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                                <i class="far fa-chart-bar" style="color: #ffffff;"></i>
                                <span class="d-block text-light">Others source</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                              {{ getRegCountBySource('Others') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.others }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 p-4 align-items-center">
                        <div class="c_bg_blue_light p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                                <i class="fas fa-people-arrows" style="color: #f5f5f5;"></i>
                                <span class="d-block text-light">Employee Refer</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                              {{ getRegCountBySource('Employee Referral') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.employee_referral }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-4 p-4 align-items-center">
                        <div class="c_bg_blue_dark p-4 d-flex flex-wrap justify-content-between rounded-lg align-items-center">
                            <div class="icon col-xl-4 col-12 mb-xl-0 mb-2 border-xl-0 border-bottom border-light pb-xl-0 pb-2">
                                <i class="fas fa-sync" style="color: #ffffff;"></i>
                                <span class="d-block text-light">Relapse patient</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">REG</span>
                              {{ getRegCountBySource('Relapse Patient') }}
                            </span>
                            <span class="d-block h1 text-white mb-0 text-center">
                              <span class="d-block font_12 mb-0">Service</span>
                              {{ dashboardData.source_wise_service.relapse_patient }}
                            </span>
                        </div>
                    </div>

                </div>
              </div>
            </div>
          <!-- </router-link> -->
        </div>
      </div>

      <!-- Center Wise Pationt Count -->
      <div class="col-md-12 p-4">
        <div class="custom_tab_table">
            <b-card no-body>
              <b-tabs pills card vertical nav-wrapper-class="col-md-4" content-class="h-auto custom_tab_content" nav-class="tb_custom_list">
                <b-tab title="CTG" active>
                  <b-card-text>
                    <div class="c_card_box total_card_box bg-transparent p-0 shadow-none">
                      <div class="row">
                        <div class="col-6">
                          <!-- <router-link to="/occ/report/center" target="_blank" class="h-100"> -->
                            <div class="icon_box h-100">
                              <div class="icon_box_img">
                                <img src="../../../../assets/img/people.svg" />
                              </div>
                              <span class="icon_box_caption">PHYSIOTHERAPY</span>
                              <span class="d-block c_card_box_content_price icon_box_price"> 
                                {{Math.round(this.dashboardData.ctg.pht_new_patient)}}
                              </span>
                              <small class="d-block text-center">New Patient</small>
                            </div>
                          <!-- </router-link> -->
                        </div>
                        <div class="col-6">
                          <!-- <router-link to="/occ/report/center" target="_blank" class="h-100"> -->
                            <div class="icon_box h-100">
                              <div class="icon_box_img">
                                <img src="../../../../assets/img/footprints.svg" />
                              </div>
                              <span class="icon_box_caption">ZERO CLUBFOOT</span>
                              <span class="d-block c_card_box_content_price icon_box_price"> 
                                {{Math.round(this.dashboardData.ctg.zcf_new_patient)}}
                              </span>
                              <small class="d-block text-center">New Patient</small>
                            </div>
                          <!-- </router-link> -->
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="CUMILLA">
                  <b-card-text>
                    <div class="c_card_box total_card_box bg-transparent p-0 shadow-none">
                      <div class="row">
                        <div class="col-6">
                          <!-- <router-link to="/occ/report/center" target="_blank" class="h-100"> -->
                            <div class="icon_box h-100">
                              <div class="icon_box_img">
                                <img src="../../../../assets/img/people.svg" />
                              </div>
                              <span class="icon_box_caption">PHYSIOTHERAPY</span>
                              <span class="d-block c_card_box_content_price icon_box_price"> 
                                {{Math.round(this.dashboardData.cm.pht_new_patient)}}
                              </span>
                              <small class="d-block text-center">New Patient</small>
                            </div>
                          <!-- </router-link> -->
                        </div>
                        <div class="col-6">
                          <!-- <router-link to="/occ/report/center" target="_blank" class="h-100"> -->
                            <div class="icon_box h-100">
                              <div class="icon_box_img">
                                <img src="../../../../assets/img/footprints.svg" />
                              </div>
                              <span class="icon_box_caption">ZERO CLUBFOOT</span>
                              <span class="d-block c_card_box_content_price icon_box_price"> 
                                {{Math.round(this.dashboardData.cm.zcf_new_patient)}}
                              </span>
                              <small class="d-block text-center">New Patient</small>
                            </div>
                          <!-- </router-link> -->
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="B.BARIA">
                  <b-card-text>
                    <div class="c_card_box total_card_box bg-transparent p-0 shadow-none">
                      <div class="row">
                        <div class="col-6">
                          <!-- <router-link to="/occ/report/center" target="_blank" class="h-100"> -->
                            <div class="icon_box h-100">
                              <div class="icon_box_img">
                                <img src="../../../../assets/img/people.svg" />
                              </div>
                              <span class="icon_box_caption">PHYSIOTHERAPY</span>
                              <span class="d-block c_card_box_content_price icon_box_price"> 
                                {{Math.round(this.dashboardData.bbaria.pht_new_patient)}}
                              </span>
                              <small class="d-block text-center">New Patient</small>
                            </div>
                          <!-- </router-link> -->
                        </div>
                        <div class="col-6">
                          <!-- <router-link to="/occ/report/center" target="_blank" class="h-100"> -->
                            <div class="icon_box h-100">
                              <div class="icon_box_img">
                                <img src="../../../../assets/img/footprints.svg" />
                              </div>
                              <span class="icon_box_caption">ZERO CLUBFOOT</span>
                              <span class="d-block c_card_box_content_price icon_box_price"> 
                                {{Math.round(this.dashboardData.bbaria.zcf_new_patient)}}
                              </span>
                              <small class="d-block text-center">New Patient</small>
                            </div>
                          <!-- </router-link> -->
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
      </div>

      <!-- Purpose Wise Reg Count -->
      <div class="col-md-12 p-4" v-if="dashboardData.purpose_wise_reg">
        <div class="c_card_box c_bg_primary">
          <!-- <router-link to="/welfare-application" target="_blank"> -->
            <div class="row">
              <div class="col-md-4">
                <div class="row justify-content-between flex-column h-100 px-4">
                  <h3 class="text-white">Purpose of Registration</h3>
                  <h4 class="text-white h1">
                    {{ perposeCount }}
                  </h4>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row row-cols-3">
                    <div class="p-4 align-items-center">
                        <div class="c_bg_orange p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon">
                                <i class="fas fa-shoe-prints fa-rotate-270" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">ZCF</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                               {{ purposeReg('ZCF') }}
                            </span>
                        </div>
                    </div>
                    <div class="p-4 align-items-center">
                        <div class="bg-success p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon">
                                <i class="fas fa-child" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">PHT</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              {{ purposeReg('PHT') }}
                            </span>
                        </div>
                    </div>
                    <div class="p-4 align-items-center">
                        <div class="c_bg_blue_light p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon">
                                <i class="fas fa-vr-cardboard" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">CP</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              {{ purposeReg('CP') }}
                            </span>
                        </div>
                    </div>
                    <div class="p-4 align-items-center">
                        <div class="c_bg_blue_dark p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon">
                                <i class="fas fa-wheelchair" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">Autism</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              {{ purposeReg('Autism') }}
                            </span>
                        </div>
                    </div>
                    <div class="p-4 align-items-center">
                        <div class="c_bg_orange p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon">
                                <i class="fas fa-baby" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">Rickets/Bow Leg</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              {{ purposeReg('Rickets/Bow Leg') }}
                            </span>
                        </div>
                    </div>
                    <div class="p-4 align-items-center">
                        <div class="bg-success p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon">
                                <i class="fas fa-child" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">Dental</span>
                            </div>
                            <span class="d-block h1 text-white mb-0 text-center">
                              {{ purposeReg('Dental') }}
                            </span>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          <!-- </router-link> -->
        </div>
      </div>
     <!-- Age wise Service Count -->
     <div class="col-md-12 p-4" v-if="ageWiseData">
        <div class="c_card_box c_bg_primary">
          <!-- <router-link :to="`/service/age-wise-service?${this.filter.period ? '&period=' + this.filter.period : ''}`" target="_blank"> -->
            <div class="row">
              <div class="col-md-8">
                <div class="row justify-content-between flex-column h-100 px-4">
                  <h3 class="text-white">Age wise Service Count</h3>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <b-dropdown id="dropdown-1" :text="ageWiseInstitute?ageWiseInstitute:'All'" class="bg-black">
                  <b-dropdown-item :active="ageWiseInstitute == ''" @click="getAgeWiseData()">All</b-dropdown-item>
                  <b-dropdown-item :active="ageWiseInstitute == 'Chittagong'" @click="getAgeWiseData('Chittagong')">Chittagong</b-dropdown-item>
                  <b-dropdown-item :active="ageWiseInstitute == 'Comilla'" @click="getAgeWiseData('Comilla')">Comilla</b-dropdown-item>
                  <b-dropdown-item :active="ageWiseInstitute == 'Brahmanbaria'" @click="getAgeWiseData('Brahmanbaria')">Brahmanbaria</b-dropdown-item>
              </b-dropdown>
              </div>
              <div class="col-md-12">
                <div class="row row-cols-2 gap-2">
                    <div class="p-4 align-items-center" v-if="ageWiseData.zcf">
                        <div class="c_bg_orange p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon col-md-3">
                                <i class="fas fa-shoe-prints fa-rotate-270" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">ZCF</span>
                            </div>
                            <div v-if="ageWiseData.zcf.baby" class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Baby</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.zcf.baby.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                      {{ ageWiseData.zcf.baby.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="ageWiseData.zcf.child" class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Child</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.zcf.child.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                      {{ ageWiseData.zcf.child.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Adult</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.zcf.adult.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.zcf.adult.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 align-items-center" v-if="ageWiseData.pht">
                        <div class="bg-success p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon col-md-3">
                                <i class="fas fa-child" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">PHT</span>
                            </div>
                            <div v-if="ageWiseData.pht.baby" class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Baby</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.pht.baby.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                      {{ ageWiseData.pht.baby.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Child</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.pht.child.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                      {{ ageWiseData.pht.child.patient }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Adult</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.pht.adult.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.pht.adult.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 align-items-center" v-if="ageWiseData.autism">
                        <div class="c_bg_blue_light p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon col-md-3">
                              <i class="fas fa-wheelchair" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">Autism</span>
                            </div>
                            <div v-if="ageWiseData.autism.baby" class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Baby</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.autism.baby.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.autism.baby.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Child</span>
                                <div class="row row-cols-1 px-4">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.autism.child.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.autism.child.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Adult</span>
                                <div class="row row-cols-1 px-4">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.autism.adult.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                      {{ ageWiseData.autism.adult.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 align-items-center" v-if="ageWiseData.cp">
                        <div class="c_bg_blue_light p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon col-md-3">
                                <i class="fas fa-vr-cardboard" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">CP</span>
                            </div>
                            <div v-if="ageWiseData.cp.baby" class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Baby</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.cp.baby.patient  }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.cp.baby.service  }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Child</span>
                                <div class="row row-cols-1 px-4">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.cp.child.patient  }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.cp.child.service  }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Adult</span>
                                <div class="row row-cols-1 px-4">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.cp.adult.patient  }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                      {{ ageWiseData.cp.adult.service  }}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-4 align-items-center" v-if="ageWiseData.bow_leg">
                        <div class="c_bg_blue_light p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon col-md-3">
                                <i class="fas fa-baby" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">Rickets/Bow Leg</span>
                            </div>
                            <div v-if="ageWiseData.bow_leg.baby" class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Baby</span>
                                <div class="row row-cols-1 no-gutters">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.bow_leg.baby.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.bow_leg.baby.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Child</span>
                                <div class="row row-cols-1 px-4">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.bow_leg.child.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                    {{ ageWiseData.bow_leg.child.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">Adult</span>
                                <div class="row row-cols-1 px-4">
                                  <div class="col border-bottom border-light pb-2 mb-2">
                                      <span class="d-block small">Patient</span>
                                      {{ ageWiseData.bow_leg.adult.patient }}
                                  </div>
                                  <div class="col">
                                    <span class="d-block small">Service</span>
                                      {{ ageWiseData.bow_leg.adult.service }}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          <!-- </router-link> -->
        </div>
      </div>

     <!-- ZCF Age wise Service Count -->
     <div class="col-md-12 p-4" v-if="zcfAgeWiseData">
        <div class="c_card_box c_bg_primary">
          <!-- <router-link :to="`/service/age-wise-service?${this.filter.period ? '&period=' + this.filter.period : ''}`" target="_blank"> -->
            <div class="row">
              <div class="col-md-8">
                <div class="row justify-content-between flex-column h-100 px-4">
                  <h3 class="text-white">ZCF Package-wise Sales</h3>
                </div>
              </div>
              <div class="col-md-4 text-right">
                <b-dropdown id="dropdown-1" :text="zcfAgeWiseInstitute?zcfAgeWiseInstitute:'All'" class="bg-black">
                  <b-dropdown-item :active="zcfAgeWiseInstitute == ''" @click="getZcfAgeWiseData()">All</b-dropdown-item>
                  <b-dropdown-item :active="zcfAgeWiseInstitute == 'Chittagong'" @click="getZcfAgeWiseData('Chittagong')">Chittagong</b-dropdown-item>
                  <b-dropdown-item :active="zcfAgeWiseInstitute == 'Comilla'" @click="getZcfAgeWiseData('Comilla')">Comilla</b-dropdown-item>
                  <b-dropdown-item :active="zcfAgeWiseInstitute == 'Brahmanbaria'" @click="getZcfAgeWiseData('Brahmanbaria')">Brahmanbaria</b-dropdown-item>
              </b-dropdown>
              </div>
              <div class="col-md-12">
                <div class="row row-cols-2 gap-2">
                    <div class="col-12 p-4 align-items-center" v-for="(zcfPackage, pKey) in zcfAgeWiseData" :key="pKey">
                        <div :class="(pKey == 'Ponseti (Plaster)')?'c_bg_orange':(pKey == 'Ponseti (Tenotomy)')?'c_bg_blue_light':'c_bg_blue_dark'" class="p-4 d-flex justify-content-between rounded-lg align-items-center">
                            <div class="icon col-md-2">
                                <i class="fas fa-shoe-prints fa-rotate-270" style="color: #ffffff;"></i>
                                <span class="d-block text-light h6 mb-0">{{ serviceTranslate[pKey] }}</span>
                            </div>
                            <div v-for="(zcfAge, aKey) in zcfPackage" :key="aKey" class="col px-1 d-block h1 text-white mb-0 text-center">
                              <div class="py-2 rounded border border-light">
                                <span class="d-block h6 border-bottom border-light pb-2 mb-2">{{ aKey }}</span>
                                <div class="row">
                                  <div class="col foot-border" v-for="(foot, fKey) in zcfAge" :key="fKey">
                                    <span class="d-block h6 border-bottom border-light pb-2 mb-2">{{ fKey>1?'Both Feet':'1 Foot' }}</span>
                                    <div class="row row-cols-1 no-gutters" style="font-size: 18px">
                                      <div class="col-5 pb-2 mb-2">
                                          <span class="d-block small">Sales</span>
                                          {{ foot.patient_count?foot.patient_count:0 }}
                                      </div>
                                      <div class="col-7">
                                        <span class="d-block small">Income</span>
                                        {{ foot.total_income?foot.total_income:0 }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          <!-- </router-link> -->
        </div>
      </div>

      <div class="col-md-12 p-4" v-if="showBarChart">
        <div class="c_card_box">
          <h3>PHT Package-wise Sales Count</h3>
          <div class="row">
            <div class="col-md-12">
              <apexchart
                class="card-rounded-bottom"
                :options="packageWiseDataChart.chartOptions"
                :series="packageWiseDataChart.series"
                type="bar"
                height="400"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 p-4" v-if="showIncomeBarChart">
        <div class="c_card_box">
          <h3>Center Wise Target vs Income</h3>
          <div class="row">
            <div class="col-md-12">
              <apexchart
                class="card-rounded-bottom"
                :options="centerIncomeWiseData.chartOptions"
                :series="centerIncomeWiseData.series"
                type="bar"
                height="400"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 p-4" v-if="serviceIncomeBarChart">
        <div class="c_card_box">
          <h3>Service Wise Target vs Income</h3>
          <div class="row">
            <div class="col-md-12">
              <apexchart
                class="card-rounded-bottom"
                :options="serviceChartOptions"
                :series="serviceSeries"
                type="bar"
                height="400"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CenterCard from "./CenterCard.vue";

const centerWiseData = [
  {
    centerName: "CTG",
    propertyName: "ctg",
  },
  {
    centerName: "Cumilla",
    propertyName: "cm",
  },
  {
    centerName: "B.baria",
    propertyName: "bbaria",
  },
];

export default {
  components: { CenterCard },
  data() {
    return {
      centerWiseData,
      centerIncomeWiseData: {
        chartOptions: {
          colors: ["#f26f25", "#002147"],
          chart: {
            toolbar: {
                show: false
            }
          },
          xaxis: {
            categories: ["Ctg Center", "Cumilla Center", "B.baria Center"],
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              }
            },
            decimalsInFloat: 0,
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top'
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
                colors: ['#333']
            },
            offsetY: -20,
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        series: [
          {
            name: "Target",
            data: [],
          },
          {
            name: "Income",
            data: [],
          },
        ],
      },
      packageWiseDataChart: {
        chartOptions: {
          colors: ["#f26f25", "#002147"],
          
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'center'
              }
            }
          },
          dataLabels: {
            enabled: false,
            style: {
                colors: ['#fff']
            },
            offsetY: -22,
            formatter: function (val) {
                return val.toFixed(0);
              },
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
              labels: {
                formatter: function (val) {
                  return val.toFixed(0);
                }
              },
              decimalsInFloat: 0,
          },

          tooltip: {
            enabled: true,
            followCursor: false,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
              var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
              var sessionCount = w.globals.initialSeries[seriesIndex].session_count[dataPointIndex];
              return '<ul class="c_tooltip">' +
                '<li>No of sales: <b>' + data + '</b></li>' + 
              '<li>Total Session : <b>' + data*sessionCount + '</b></li>' + 
              '</ul>';
            }
          }
        },
          
        series: [
          {
            name: "No of Sales",
            data: [],
          },
        ],
     },
      serviceChartOptions: {
        colors: ["#f26f25", "#002147"],
        chart: {
          toolbar: {
              show: false
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
              colors: ['#333']
          },
          offsetY: -20,
          formatter: function (val) {
              return val.toFixed(0);
            },
        },
        xaxis: {
          categories: ["PHYSIOTHERAPY", "ZERO CLUBFOOT", "DENTAL"],
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            }
          },
          decimalsInFloat: 0,
        }
      },
      serviceSeries: [
        {
          name: "Target",
          data: [],
        },
        {
          name: "Income",
          data: [],
        },
      ],
      source_wise_reg: [],
      showBarChart: false,
      toggleItem: false,
      showIncomeBarChart: false,
      serviceIncomeBarChart: false,
      ageWiseData: {},
      zcfAgeWiseData: {},
      ageWiseInstitute:'',
      zcfAgeWiseInstitute:'',
      serviceTranslate: {
        "Ponseti (Plaster)": "Casting",
        "Ponseti (Tenotomy)": "Tenotomy",
        "Brace": "Bracing",
      }
    };
  },
  props: {
    dashboardData: {
      type: Object,
      default: {},
    },
    filter: {
      type: Object,
      default: {},
    },
  },
  computed: {
    regCount(){ 
      // let RegWiseData = this.dashboardData.source_wise_reg;
      // let excludeObj = [null,'register'];
      // let sourceData = RegWiseData.filter((item) => {
      //   if(excludeObj.includes(item.platform)){
      //     return false
      //   }
      //   return item.platform
        
      // });

      return this.dashboardData.source_wise_reg.reduce((Sum, item) => item.reg_count  + Sum  ,0);
      
    },
    perposeCount(){ 
      return this.dashboardData.purpose_wise_reg.reduce((Sum, item) => (item.reg_purpose)?item.reg_count  + Sum:0,0);
    },
  },
  methods: {
    sourceWiseService() {
      return this.dashboardData.source_wise_service
        ? parseFloat(
            this.dashboardData.source_wise_service.others +
              this.dashboardData.source_wise_service.register +
              this.dashboardData.source_wise_service.patient_referral +
              this.dashboardData.source_wise_service.doctor_referral +
              this.dashboardData.source_wise_service.social +
              this.dashboardData.source_wise_service.poster +
              this.dashboardData.source_wise_service.employee_referral +
              this.dashboardData.source_wise_service.relapse_patient
          ).toFixed(0)
        : 0;
    },
    totalTarget() {
      return this.dashboardData.targets
        ? parseFloat(
            this.dashboardData.targets.dental_target +
              this.dashboardData.targets.pht_target +
              this.dashboardData.targets.zcf_target 

          ).toFixed(0)
        : 0;
    },
    purposeReg(purpose) {
      let purposeSource = this.dashboardData.purpose_wise_reg.find((item) => item.reg_purpose == purpose);
      if(purposeSource){
        return purposeSource.reg_count
      }else{
        return 0;
      }
    },
    serviceWiseTotal(service) {
      return this.dashboardData.ctg
        ? parseFloat(
            this.dashboardData.ctg[service] +
              this.dashboardData.cm[service] +
              this.dashboardData.bbaria[service]
          ).toFixed(0)
        : 0;
    },

    getRegCountBySource(platform){
      let regSource = this.dashboardData.source_wise_reg.find((item) => item.platform == platform);
      if(regSource){
        return regSource.reg_count
      }else{
        return 0;
      }
    },
    
    getsServiceWiseIncome() {
      this.serviceIncomeBarChart = false;

      setTimeout(() => {
        if (this.dashboardData.targets) {
          this.serviceSeries[0].data = [
          this.dashboardData.targets.pht_target,
            this.dashboardData.targets.zcf_target,
              this.dashboardData.targets.dental_target
          ];
          this.serviceSeries[1].data = [
            this.dashboardData.ctg.pht_income +
              this.dashboardData.cm.pht_income +
              this.dashboardData.bbaria.pht_income,
            this.dashboardData.ctg.zcf_income +
              this.dashboardData.cm.zcf_income +
              this.dashboardData.bbaria.zcf_income,
            this.dashboardData.ctg.dental_income +
              this.dashboardData.cm.dental_income +
              this.dashboardData.bbaria.dental_income,
          ];
        }
        this.serviceIncomeBarChart = true;
      }, 500);
    },

    getCenterIncomeWiseData() {
      this.showIncomeBarChart = false;
      setTimeout(() => {
        if (this.dashboardData.ctg) {
          this.centerIncomeWiseData.series[0].data = [
            this.dashboardData.ctg.target_income,
            this.dashboardData.cm.target_income,
            this.dashboardData.bbaria.target_income,
          ];
          this.centerIncomeWiseData.series[1].data = [
            this.dashboardData.ctg.income,
            this.dashboardData.cm.income,
            this.dashboardData.bbaria.income,
          ];
        }
        this.showIncomeBarChart = true;
      }, 600);
    },
    getPackageWiseSales() {
      this.showBarChart = false;
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/orko-dashboard/package-wise-sales?api_token=${localStorage.getItem(
            "api_token"
          )}${this.filter.type ? "&type=" + this.filter.type : ""}${
            this.filter.period ? "&period=" + this.filter.period : ""
          }`
        )
        .then((res) => {
          let packageData = res.data.data;
          this.packageWiseDataChart.series[0].data = packageData.map((item) =>
            parseInt(item.invoice_count)
          );

          this.packageWiseDataChart.series[0].session_count = packageData.map((item) =>
          parseInt(item.no_of_sessions)
          );

          this.packageWiseDataChart.chartOptions.xaxis.categories = packageData.map(
            (item) =>
              `Pkg${item.package_no} ${item.no_of_sessions ? "-S" + item.no_of_sessions : "" } ${
                item.label ? " (" + item.label + ")" : ""
              }`
          );
        
          setTimeout(() => {
            this.showBarChart = true;
          }, 250);
        });
        
    },
    getAgeWiseData(institute) {
      this.ageWiseInstitute = institute?institute:'';
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko-dashboard/age-wise-services?api_token=${localStorage.getItem('api_token')}${this.filter.type ? '&type=' + this.filter.type : ''}
      ${this.filter.period ? '&period=' + this.filter.period : ''}${this.ageWiseInstitute ? '&institute_name=' + this.ageWiseInstitute : ''}`)
      .then(res => {
        this.ageWiseData = res.data.data;
        this.loading = false;
      })
    },
    getZcfAgeWiseData(institute) {
      this.zcfAgeWiseInstitute = institute?institute:'';
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko-dashboard/zcf-package-wise-sales?api_token=${localStorage.getItem('api_token')}${this.filter.type ? '&type=' + this.filter.type : ''}
      ${this.filter.period ? '&period=' + this.filter.period : ''}${this.zcfAgeWiseInstitute ? '&institute_name=' + this.zcfAgeWiseInstitute : ''}`)
      .then(res => {
        this.zcfAgeWiseData = res.data.data;
        console.log(this.zcfAgeWiseData, 'this.zcfAgeWiseData');
        this.loading = false;
      })
    },
  },

  watch: {
    filter: {
      handler() {
        this.getPackageWiseSales();
        this.getCenterIncomeWiseData();
        this.getsServiceWiseIncome();
        this.getAgeWiseData();
        this.getZcfAgeWiseData();
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.getPackageWiseSales();
    this.getAgeWiseData();
    setTimeout(() => {
      this.getCenterIncomeWiseData();
      this.getsServiceWiseIncome();
      this.getZcfAgeWiseData();
    }, 2000);
  },
};
</script>

<style lang="scss">
.c_card_box {
  min-height: 150px;
  height: 100%;
  padding: 20px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  background-clip: padding-box;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  &.total_card_box {
    background-color: #002147;

    .c_card_box_title {
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
    }

    .icon_box {
      background: #011731;
      padding: 20px;
      border-radius: 20px;
      text-align: center;
      color: #fff;
      &_img {
        width: 40px;
        margin: 0 auto;
        img {
          max-width: 100%;
        }
      }
      &_price{
        color: #f26f25;
      }
    }
  }
  &_inner {
    // min-height: 150px;
    height: 100%;
  }

  &_title {
    font-size: 22px;
    font-weight: 600;
  }
  &_content {
    &_price {
      font-size: 24px;
      font-weight: 600;
      display: block;
    }
  }
}

.icon {
  text-align: center;
  span {
    font-size: 8px;
    font-weight: 600;
  }
}

.show_more_button {
  border: 1px solid #002147;
  border-radius: 100px;
  text-align: center;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 600;
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: center;
}


.custom_tab_table{
  .nav{
      &.tb_custom_list{
        background: #011831 !important;
        padding-right: 0 !important;

        li{
          margin-right: 0;
          a{
            &:hover,
            &.active{
              background-color: #012047 !important;
              border-radius: 10px 0 0 10px;
              color:#f26f25 !important;
            }
          }
        }
      }
    }

  .tab-content{
    &.custom_tab_content{
      margin-left: 0;
      margin-top: 0;
      background: #012047;
      border-left: none !important;
      padding: 10px;
      border-radius: 0;
    }
  }
  
}

.c_card_box_n{
  padding: 0 !important;
  padding-top: 18px !important;
  background: #011831 !important;
  min-height: 0;
  color: #fff;
}
.icon_round_box{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  min-height: 50px;
  background: #f26f25;
  margin: 0 auto;
  margin-top: 20px;
  span{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
  }
}

.c_tooltip{
  padding: 10px 0px;
  margin: 0;
  list-style: none;
  background: #002147;
  color: #fff;
  li{
    font-size: 12px;
    display: flex;
    align-content: center;
    align-items: center;
    border-bottom: 1px solid #fff;
    padding: 5px 10px;
    b{
      padding-left: 5px;
      color: #f26f25;
    }
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.small{
  font-size: 10px;
}
.foot-border{
  &:first-of-type {
    border-right: 1px dashed;
    padding-right: 0;
  }
  &:last-of-type {
    padding-left: 0;
  }
}
</style>
