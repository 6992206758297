<template>
    <div class="container">
        <div class="row gutter">
            <!-- Start Title -->
            <!-- <div class="col-sm-12 total_card p-4 mt-4">
                <h1 class="text-center font-weight-bolder text-capitalize">
                    Orko CRM Activity
                </h1>
            </div> -->

             <!--Start Call -->
             <div class="col-md-12 p-4" v-if="crmActivity.call">
                <router-link to="/occ/call/center" target='_blank'>
                    <div class="c_card_box c_bg_primary">
                        <div class="row py-4">
                            <div class="col-md-4">
                                <div class="row justify-content-between flex-column h-100 px-4">
                                    <h3 class="text-white">Call Center Data</h3>
                                    <h4 class="text-white h1">
                                        <span class="text-muted d-block h6">Total Call</span>
                                        {{ crmActivity.call.total }}
                                    </h4>
                                </div>
                            </div>
                            <div class="col-md-8 border-left">
                                <div class="row row-cols-2">
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-phone-volume" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Inbound</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.inbound }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-phone-alt" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Outbound</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.outbound }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    
                    </div>
                </router-link>
            </div>

            <!--Start Call -->
            <div class="col-md-12 p-4" v-if="crmActivity.call">
                <router-link to="/occ/call/center" target='_blank'>
                    <div class="c_card_box c_bg_primary">
                        <div class="row py-4">
                            <div class="col-md-2">
                                <div class="row justify-content-between flex-column h-100 px-4">
                                    <h3 class="text-white">Inbound Call Source</h3>
                                </div>
                            </div>
                            <div class="col-md-10 border-left">
                                <div class="row">
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-vr-cardboard" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Sticker Source</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.source_poster }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-share-alt" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Social Source</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.source_social }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-user-md" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Doctor Refer</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.source_doctor_refer }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-hospital-user" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Patient Refer</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.source_patient_refer }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-people-arrows" style="color: rgb(245, 245, 245);"></i>
                                                <span class="d-block text-light">Employee Refer</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.source_employee_refer }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="far fa-chart-bar" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Others source</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.call.source_others }}
                                            </span>
                                        </div>
                                    </div>
                            </div>
                            </div>
                            
                        </div>
                    
                    </div>
                </router-link>
            </div>
            <!--Start sms -->
            <div class="col-md-6 p-4" v-if="crmActivity.sms">
                <router-link to="/messages" target='_blank'>
                    <div class="c_card_box c_bg_primary">
                        <div class="row py-4">
                            <div class="col-md-4">
                                <div class="row justify-content-between flex-column h-100 px-4">
                                    <h3 class="text-white">SMS</h3>
                                    <h4 class="text-white h1">
                                        <span class="text-muted d-block h6">Total SMS</span>
                                        {{ crmActivity.sms.total }}
                                    </h4>
                                </div>
                            </div>
                            <div class="col-md-8 border-left">
                                <div class="row row-cols-2">
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-phone-volume" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">Bulk</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.sms.bulk }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col p-4 align-items-center">
                                        <div class="c_bg_primary border p-4 d-flex justify-content-between rounded-lg align-items-center">
                                            <div class="icon">
                                                <i class="fas fa-phone-alt" style="color: #ffffff;"></i>
                                                <span class="d-block text-light">single</span>
                                            </div>
                                            <span class="d-block h1 text-white mb-0">
                                                {{ crmActivity.sms.single }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </router-link>
            </div>

            <!--Start audit -->
            <div class="col-md-6 p-4" v-if="crmActivity.audit">
                <router-link to="/occ/report/quality-control" target='_blank'>
                <div class="c_card_box c_bg_primary">
                    <div class="row py-4">
                        <div class="col-md-4">
                            <div class="row justify-content-between flex-column h-100 px-4">
                                <h3 class="text-white">Audit</h3>
                                <h4 class="text-white h1">
                                    <span class="text-muted d-block h6">Total Audit</span>
                                    {{ crmActivity.audit.total }}
                                </h4>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </router-link>
            </div>

        </div>
    </div>
</template>

<script>


export default {
    components: {},
    props: {
        crmActivity: {
            type: Object,
            default: {}
        }
    },
    data(){
        return{
        }
    }
}
</script>