<template>
    <div class="c_card_box">
        <div class="d-flex flex-column c_card_box_inner">
            <div class="row align-items-center mb-3">
                <h6 class="c_card_box_title col-md-6 mb-md-0 text-uppercase">
                    {{ centerName }}
                </h6>
                <div class="d-flex justify-content-end align-items-end text-end col-md-6">
                    <div class="c_card_box_content">
                        <span class="d-block text-muted">Center Balance</span>
                        <span class="d-block c_card_box_content_price">&#2547; {{Math.floor(centerData.income)}}</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <ul class="c_card_box_lists mb-0 px-4 ml-4 border-left border-light">
                        <template v-for="item in centerKeys">
                            <li v-if="item.isActive">
                                <div class="d-flex my-1 align-items-center justify-content-between bg-light bg-gradient p-2 px-3 rounded-lg">
                                    <span class="text-uppercase col-lg-8 p-0">{{ item.label }}</span>
                                    <span class="font-weight-bolder col-lg-4 mb-0 h6 p-0 text-right">
                                        <template><span v-if="item.isPrice">&#2547;</span>{{Math.round(centerData[item.key])}}</template>
                                    </span>
                                </div>
                            </li>
                            
                            <li v-show="seeMore" v-else-if="!item.isActive">
                                <div class="d-flex my-1 align-items-center justify-content-between bg-light bg-gradient p-2 px-3 rounded-lg">
                                    <span class="text-uppercase col-lg-8 p-0">{{ item.label }}</span>
                                    <span class="font-weight-bolder mb-0 h6 col-lg-4 p-0 text-right">
                                        <template><span v-if="item.isPrice">&#2547;</span> {{Math.round(centerData[item.key])}}</template>
                                    </span>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    props: {
        centerData: {
            type: Object,
            default() {
                return {}
            }
        },
        centerName: {
            type: String,
            default:''
        },
        seeMore: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            centerKeys: [
                { key: "pht_income", label: "PHYSIOTHERAPY", isPrice: true, isActive: true},
                { key: "zcf_income", label: "ZERO CLUBFOOT", isPrice: true, isActive: true},
                { key: "dental_income", label: "DENTAL", isPrice: true, isActive: true},
                { key: "plaster_material_income", label: "PLASTER MATERIAL", isPrice: true, isActive: true},
                { key: "in_house_doc_consult", label: "Specialised  CONSULTATION", isPrice: true, isActive: true},
                { key: "home_service", label: "HOME SERVICE", isPrice: true, isActive: true},
                { key: "no_of_package", label: "NO. OF PHT PACKAGE PURCHASED", isPrice: false, isActive: false},
                { key: "no_of_zcf_package", label: "NO. OF ZCF PACKAGE PURCHASED", isPrice: false, isActive: false},
                { key: "no_of_service", label: "NO. OF SERVICE", isPrice: false, isActive: false},
                { key: "no_of_zcf_service", label: "NO. OF ZCF SERVICE", isPrice: false, isActive: false},
                { key: "no_of_pht_service", label: "NO. OF PHT SERVICE", isPrice: false, isActive: false},
                { key: "no_of_dental_service", label: "NO. OF DENTAL SERVICE", isPrice: false, isActive: false},
                { key: "no_of_in_house_doc_consult", label: "NO. OF I. CONSULTATION", isPrice: false, isActive: false},
                { key: "no_of_home_service", label: "NO. OF HOME SERVICE", isPrice: false, isActive: false},
            ],
        }
    },
}
</script>
<style lang="scss" scoped>
.c_card_box{
    height: auto;
    &_lists{
        list-style: none;
    }

}

</style>